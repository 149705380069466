import * as React from "react"
import {useState} from "react";
import DocumentationContent from "../../components/DocumentationContent"
import DocumentationSidebar from "../../components/DocumentationSidebar"
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/Layout'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import {FullSlideshowLightboxExample, SlideshowLightboxExampleDoc, SlideshowLightboxExample, 
  PropsDoc, ThemesDoc} from "../../components/Examples";
import Content, { HTMLContent } from '../../components/Content'
import Documentation from '../../components/Documentation'
import {SlideshowLightbox, Image} from "lightbox.js-react";
import { CopyBlock, CodeBlock, atomOneDark, solarizedDark, xt256 } from "react-code-blocks";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';



const VideoAndImageDemo = () => {
    const items = [
        {
          type: "htmlVideo", 
          videoSrc:"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
          thumbnail:"https://peach.blender.org/wp-content/uploads/bbb-splash.png",
          alt: "Poster for the Big Buck Bunny film, featuring the bunny character in a green field, along with a purple butterfly"
        }, 
        { 
            type: "yt", 
            videoID :"IWVJq-4zW24",
            thumbnail: "https://img.youtube.com/vi/IWVJq-4zW24/hqdefault.jpg",
            alt: "Universe with stars"
          },
        { 
            src: "https://images.pexels.com/photos/247600/pexels-photo-247600.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", 
            alt: "Blue lake and trees in the distance, along with a blue sky and clouds"
          },
 
      ]

      let [isOpen, setIsOpen] = useState(false)

      return (
        <div>
          <SlideshowLightbox images={items} lightboxIdentifier="lbox2" showThumbnails={true}
          className="container grid grid-cols-3 gap-2 mx-auto">
          {items.map((item) => (
            <img
              src={item.thumbnail || item.src}
              alt={item.alt}
              height={"100%"}
              width={500}
              data-lightboxjs="lbox2"
              className="h-full object-cover"
              style={{height: "100%"}}
            />
            ))}
          </SlideshowLightbox>
        </div>

      )
}

const VideoSupportDemo = () => {
    const items = [
        {
          type: "htmlVideo", 
          videoSrc:"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
          thumbnail:"https://peach.blender.org/wp-content/uploads/bbb-splash.png",
          alt: "Poster for the Big Buck Bunny film, featuring the bunny character in a green field, along with a purple butterfly"
        }, 
        { 
            type: "yt", 
            videoID :"IWVJq-4zW24",
            thumbnail: "https://img.youtube.com/vi/IWVJq-4zW24/hqdefault.jpg",
            alt: "Universe with stars"
        }
      ]

      let [isOpen, setIsOpen] = useState(false)

      return (
        <div>
          {/* <button onClick={() => {setIsOpen(true)}}>Open Lightbox</button> */}
          <SlideshowLightbox images={items} lightboxIdentifier="lbox123" showThumbnails={true}
          className=" grid grid-cols-2 gap-2 mx-auto w-4/5 h-full w-full">
          {items.map((item) => (
            <div className="h-full">
                <img
                src={item.thumbnail || item.src}
                alt={item.alt}
                height={500}
                width={500}
                data-lightboxjs="lbox123"
                className="h-full object-cover"
                style={{height: "100%"}}
                />
            </div>

            ))}
          </SlideshowLightbox>
        </div>

      )
}

export default function VideoSupportGuide () {

  const showLineNumbers = true;
  const wrapLines = true;
  const codeBlock = false;

      const items = [
        {
          type: "htmlVideo", 
          videoSrc:"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
          thumbnail:"https://peach.blender.org/wp-content/uploads/bbb-splash.png",
          alt: "Poster for the Big Buck Bunny film, featuring the bunny character in a green field, along with a purple butterfly"
        }, 
        { 
            type: "yt", 
            videoID :"IWVJq-4zW24",
            thumbnail: "https://img.youtube.com/vi/IWVJq-4zW24/hqdefault.jpg",
            alt: "Universe with stars"
          },
      ]

  return <Layout>
  <Header />
  <section className="section section--gradient font-inter bg_white">
  
      <div className="pt-4">
      <main className="flex-grow">

          <section>
          <div className="max-w-6xl mx-auto px-2 sm:px-2">
              <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Main content */}
              <div className="md:flex md:justify-between" data-sticky-container>

                  <DocumentationSidebar />

                  <div className="md:flex-grow documentation font-inter">

                    {/* <Header /> */}
                    <div className="text-lg text-gray-600">
                      <h1 className="h1 text-gray-900 mb-4 font-bold">Video Support</h1>

                      <p className="mb-8">
                      Lightbox.js supports the HTML5 video element, as well as YouTube embeds, and in this guide, 
                      we'll be taking a look at how to display these video elements in the lightbox.

                      </p>

                      
                    <h3>Demo</h3>
                    <p>Here is a quick demo if you'd like to see the video functionality in action. 
                    </p>
                    <p>
                    Just click an image to view the lightbox:
                    </p>
                    <VideoSupportDemo />
                      

                    <h3 id="import" className="h3 text-gray-900 font-bold text-3xl mt-4" style={{ scrollMarginTop: '100px' }}>Import</h3>

                    <p>
                    Firstly, import the <code>SlideshowLightbox</code> component required, as well as the CSS file which provides 
                    the styling for the library:
                    </p>

                    <div className="mb-8">
                        <SyntaxHighlighter language="jsx" style={oneDark}  className="mb-8 codeBlock">
                        {`import 'lightbox.js-react/dist/index.css'\nimport {SlideshowLightbox} from 'lightbox.js-react'`}
                        </SyntaxHighlighter>
                    </div>

                    <h4 className="h4 text-gray-900 ">Adding a license key</h4>

                    <p>Next, a license key will need to be initialized in order to use the lightbox. If you require a license key,
                      you can purchase an individual or team license <a className="text-underline text-blue-600" href="/pricing">here</a>.
                      However if your project is open-source, just use the <a className="text-underline text-blue-600" href="/contact/">contact form here</a> to get a free 
                      license key, which you can use for your project. </p>

                      <p className="flex mt-8 items-center text-base px-3 py-4 text-gray-800 bg-blue-100 border border-blue-200 rounded mb-8">
                                <svg className="w-4 h-4 fill-current text-blue-500 flex-shrink-0 mr-2" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm2.629 11.618L8 10.236l-2.629 1.382.5-2.927-2.124-2.073 2.939-.427L8 3.528l1.314 2.663 2.939.427-2.126 2.073.502 2.927z" fill="#338CF5" fillRule="nonzero" />
                                </svg>
                     <span>Also, we're running a promotion currently, where you can get a 20% off discount with the coupon code OFFER20 at checkout.</span>
                    </p>            

                      <p>The license key is initialized like so:</p>

                      <SyntaxHighlighter language="jsx" style={oneDark}  className="mb-12 codeBlock">
                      {`useEffect(() => {
    initLightboxJS("Insert License key", "Insert plan type here");
});`}
                      </SyntaxHighlighter>

                    <p>
                      The two plan types are <code>individual</code> and <code>team</code>.
                    </p>

                    <h3 id="html5videoUsage" className="text-gray-900 mb-4 text-3xl font-semibold" style={{ scrollMarginTop: '100px' }}>
                      Adding Videos
                      </h3>
                   <p>Firstly, we're going to render a HTML5 video element in the lightbox, 
                    along with a YouTube video embed. 
                    </p>
                    <p>To do so, create an array of metadata objects, with each object containing the relevant
                      metadata about the video. More information on the properties of each can be found below.
                    </p>
                   <div className="mb-8">
                        <SyntaxHighlighter language="js" style={oneDark} className="mb-8 codeBlock">
                        {`const media = [
  {
    type: "htmlVideo", 
    videoSrc:"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    thumbnail:"https://peach.blender.org/wp-content/uploads/bbb-splash.png",
    alt: "Poster for the Big Buck Bunny film, featuring the bunny character in a green field, along with a purple butterfly"
  },
  { 
    type: "yt", 
    videoID :"IWVJq-4zW24",
    thumbnail: "https://img.youtube.com/vi/IWVJq-4zW24/hqdefault.jpg",
    alt: "Universe with stars"
  }
]
`}
              
                        </SyntaxHighlighter>
                
                    </div>

                    <p>Then just pass this array to the <code>SlideshowLightbox</code> component:</p>
                    
                    <div className="mb-8">
                        <SyntaxHighlighter language="jsx" style={oneDark} className="mb-8 codeBlock">
                        {`<SlideshowLightbox images={media} lightboxIdentifier="lbox1" showThumbnails={true}>
        
        {items.map((item) => (
            <img
              src={item.thumbnail || item.src}
              alt={item.alt}
              height={500}
              width={500}
              data-lightboxjs="lbox1"
            />
        ))}
            
</SlideshowLightbox>`}
                        </SyntaxHighlighter>
                    </div>

                    <p className="pt-4">Here is the result:</p>

                            <SlideshowLightbox images={items} lightboxIdentifier="lboxDemo" showThumbnails={true}
          className="container grid grid-cols-3 gap-2 mx-auto">
          {items.map((item) => (
            <img
              src={item.thumbnail || item.src}
              alt={item.alt}
              height={"100%"}
              width={500}
              data-lightboxjs="lboxDemo"
              className="h-full object-cover"
              style={{height: "100%"}}
            />
            ))}
          </SlideshowLightbox>

                    <h3 className="mt-8">Video Properties</h3>
                    <p className="pt-4">There are various properties which can be used to contain metadata about the video element, 
                        such as the height and width, thumbnail image and so forth. Here's a guide to the properties 
                        for the HTML5 video element:
                    </p>

                    <h4>HTML5 Video Embed Properties</h4>
                    <ul className="list-disc mt-4 pl-4">
                        <li><b>type</b>: There are two types of videos which can be rendered, YouTube embeds and HTML5 video elements.
                        For YouTube embeds, the type is <code>yt</code> and for HTML5 video elements the type is <code>htmlVideo</code></li>
                        <li><b>videoSrc</b>: Link to the video source</li>
                        <li><b>autoPlay</b>: Whether or not to automatically start playing the video once the lightbox is opened. (Boolean: <code>true</code>
                        /<code>false</code></li>
                        <li><b>thumbnail</b>: Link to the thumbnail image.</li>
                        <li><b>videoHeight</b>: Height of the HTML5 <code>video</code> element in pixels (default is 500px)</li>
                        <li><b>videoWidth</b>: Width of the HTML5 <code>video</code> element in pixels (default is 900px)</li>

                    </ul>

                    <p className="pt-4">For example,</p>

                    <SyntaxHighlighter language="js" style={oneDark} className="mb-8 codeBlock">
                        {`const media = [
  {
    type: "htmlVideo", 
    videoSrc:"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    thumbnail:"https://peach.blender.org/wp-content/uploads/bbb-splash.png",
    alt: "Poster for the Big Buck Bunny film, featuring the bunny character in a green field, along with a purple butterfly"
  }
]
`}

                
                        </SyntaxHighlighter>

                    <h3 id="html5videoUsage" className="mt-12 text-gray-900 mb-4 text-3xl font-semibold" style={{ scrollMarginTop: '100px' }}>
                        Adding A YouTube Video Embed</h3>

                        <SyntaxHighlighter language="js" style={oneDark} className="mb-8 codeBlock">
                        {`const media = [
  { 
    type: "yt", 
    videoID :"IWVJq-4zW24",
    thumbnail: "https://img.youtube.com/vi/IWVJq-4zW24/hqdefault.jpg",
    alt: "Universe with stars"
  }
]
`}
                        </SyntaxHighlighter>

                  

                    <h4 className="pt-4">YouTube Video Embed Properties</h4>
                    <ul className="list-disc list mt-4 ml-8">
                        <li><b>type</b> For YouTube embeds, the type should be set to <code>yt</code> (For HTML5 video elements the type is <code>htmlVideo</code>)</li>
                        <li><b>videoID</b>: YouTube video ID</li>
                        <li><b>autoPlay</b>: Whether or not to automatically start playing the YouTube video once the lightbox is opened. (Boolean: <code>true</code>
                        /<code>false</code></li>
                        <li><b>thumbnail</b>: Link to the thumbnail image. If displaying a YouTube embed, you 
                        can fetch the thumbnail image at this link: https://img.youtube.com/vi/VIDEO_ID_HERE/hqdefault.jpg </li>
                        <li><b>videoHeight</b>: Height of the YouTube <code>video</code> element in pixels (default is 500px)</li>
                        <li><b>videoWidth</b>: Width of the YouTube <code>video</code> element in pixels (default is 900px)</li>

                    </ul>

                    <h1>Demo</h1>
                    <p>Here is a quick demo containing a HTML5 video element, YouTube embed, and image:</p>
                    <VideoAndImageDemo />


                    <article className="mb-8 mt-12">
                      <h2 className="h2 text-gray-900 mb-4 text-3xl font-semibold">Customization</h2>
                    <p>If you'd like to customize the lightbox, be sure to take a look at the <Link className="text-underline text-blue-600" 
                    to="/docs/SlideshowLightbox">
                        SlideshowLightbox</Link> {" "}
                     documentation, which lists the various customization options and props you can update to edit the 
                    theme and so forth.</p>
                   

      </article>
      
    </div>

    {/* Related content */}
    <div className="mt-8">
      <h3 className="h3 mb-8 font-semibold">Related</h3>
      <Link className="flex justify-between items-center p-4 rounded border border-gray-200 
      transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" to="/docs/SlideshowLightbox">
        <div>
          <div className="text-normal font-medium mb-1">Docs for the SlideshowLightbox component</div>
          <div className="text-sm text-gray-600">See how to customize the SlideshowLightbox in the complete documentation here.</div>
        </div>
        <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
        </svg>
      </Link>
      <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition 
      duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="https://github.com/silvia-odwyer/lightbox.js">
        <div>
          <div className="text-normal font-medium mb-1">Feature request</div>
          <div className="text-sm text-gray-600">Want to request a new feature? Just open an issue in our GitHub repository!</div>
        </div>
        <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
        </svg>
      </a>
      <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
    </div>

    {/* <Feedback /> */}

  </div>

              </div>

              </div>
          </div>
          </section>

          </main>
      </div>
          
  </section>
    <Footer overrideDarkTheme={true} />
  </Layout>
  
}